import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reporting_full_empty = _resolveComponent("reporting-full-empty")!
  const _component_reporting_navigation = _resolveComponent("reporting-navigation")!
  const _component_report_cards = _resolveComponent("report-cards")!
  const _component_reporting_timeline = _resolveComponent("reporting-timeline")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isEmptyMode)
      ? (_openBlock(), _createBlock(_component_reporting_full_empty, {
          key: 0,
          image: "calls.png",
          title: "Calls reporting",
          description: "Buy a number or connect your messaging provider (CPaaS), such as Twilio, to start making and tracking calls. Detailed call reporting will appear here once available.",
          tooltips: _ctx.tooltips
        }, null, 8, ["tooltips"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_reporting_navigation, {
            filters: _ctx.filters,
            "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
            numbers: "",
            class: "mb-3"
          }, null, 8, ["filters"]),
          _createVNode(_component_report_cards, {
            cards: _ctx.stats,
            "no-hover": "",
            class: "mb-6"
          }, null, 8, ["cards"]),
          _createVNode(_component_reporting_timeline, {
            data: _ctx.timelineChart,
            "timeline-options": _ctx.timelineCallsOptions
          }, null, 8, ["data", "timeline-options"])
        ], 64))
  ]))
}